import React, { useState } from 'react';
import './App.css';
import logo from './cpt.png';  // Import the logo image
import sfLogo from './sf.png';  // Import the "powered by" image
import questions from './questions';

function App() {
  const [answers, setAnswers] = useState(Array(questions.length).fill(false));
  const [submitted, setSubmitted] = useState(false);

  const handleCheckboxChange = (index) => {
    const newAnswers = [...answers];
    newAnswers[index] = !newAnswers[index];
    setAnswers(newAnswers);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
  };

  const calculateScore = () => {
    const positiveAnswers = answers.filter(answer => answer).length;
    return 100 - positiveAnswers;
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-banner" alt="Creator Purity Test Banner" />  {/* Image as banner */}
        {!submitted ? (
          <form onSubmit={handleSubmit}>
            <div className="questions-container">
              {questions.map((question, index) => (
                <div
                  key={index}
                  className={`question-item ${answers[index] ? 'checked' : ''}`}
                  onClick={() => handleCheckboxChange(index)}
                >
                  <input
                    type="checkbox"
                    id={`question-${index}`}
                    checked={answers[index]}
                    onChange={() => handleCheckboxChange(index)}
                  />
                  <label
                    htmlFor={`question-${index}`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {question}
                  </label>
                </div>
              ))}
            </div>
            <button type="submit">Calculate Score</button>
          </form>
        ) : (
          <div>
            <div id="result-section">
              <h2>Your Purity Score: {calculateScore()}</h2>
              <p>
                A higher score means you're more "pure", and a lower score means you're more experienced.
                <br />
                What was your score? Share it online with <b>#CreatorPurityTest</b>!
              </p>
            </div>
            <button onClick={() => setSubmitted(false)}>Retake the Test</button>
            {/* Tagline with the "powered by" image */}
            <div className='powered-by-container'>
              <p style={{ fontSize: '2vh' }}>Powered by</p>
              <a href="https://bit.ly/FAYANATION" target="_blank" rel="noopener noreferrer">
                <img src={sfLogo} alt="Supafaya" style={{ width: '150px', height: 'auto' }} />
              </a>
            </div>
          </div>
        )}
      </header>
    </div>
  );
}

export default App;
