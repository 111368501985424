// questions.js
const questions = [
    'Created a "close friends" list for more exclusive, behind-the-scenes content.',
    'Posted something controversial just to spark engagement.',
    'Used clickbait or a misleading thumbnail for views.',
    'Participated in a viral trend that pushed boundaries.',
    'Flirted with fans or followers in DMs.',
    'Posted a thirst trap that skyrocketed your engagement.',
    'Made a suggestive joke or innuendo in your content.',
    'Used seductive or provocative language to sell a product or service.',
    'Posted content in lingerie, swimwear, or less.',
    'Teased an exclusive reveal or announcement to drive suspense.',
    'Created an anonymous account to monitor competition or haters.',
    'Reposted a spicy meme or NSFW joke.',
    'Blocked a hater or troll mid-argument.',
    'Had a public feud with another creator.',
    'Called someone out for stealing your content.',
    'Got into a private feud that spilled into your content.',
    'Posted a "subtweet" or passive-aggressive story about someone.',
    'Created content that sparked a heated debate in the comments.',
    'Deleted comments to keep your image clean.',
    'Posted content purely to bait thirsty comments.',
    'Dated or hooked up with another content creator.',
    'Had your DMs leaked or exposed online.',
    'Used someone else’s relationship drama for content.',
    'Created content or memes about your ex.',
    'Used a romantic or spicy story to tease new content.',
    'Got drunk (or acted drunk) on a live stream or video.',
    'Made fun of a celebrity or public figure for clout.',
    'Used suggestive emojis to hint at something spicy.',
    'Sent a risky DM to another influencer or follower.',
    'Posted an "apology video" for past content.',
    'Had a photo or video banned for being too risqué.',
    'Created a “fake scandal” or controversy to get attention.',
    'Leaked your own private information for sympathy or buzz.',
    'Used someone else’s scandal for a juicy reaction video.',
    'Participated in a “smash or pass” challenge.',
    'Exchanged “favors” with another creator for promotion.',
    'Pushed boundaries with a suggestive challenge or trend.',
    'Made fun of a subscriber’s or follower’s profile picture.',
    'Trolled another creator’s live stream or comments.',
    'Received or sent fan fiction about yourself.',
    'Had rumors spread about your relationships or love life.',
    'Posted “fake drama” content to hook viewers.',
    'Used a dramatic clickbait title with a thumbnail that had nothing to do with the content.',
    'Had your private photos or videos leaked online.',
    'Sold provocative content on OnlyFans, Patreon, or similar.',
    'Posted content while clearly not sober.',
    'Posted a steamy dance video or challenge that blew up.',
    'Received a message from a follower that made a positive impact on you.',
    'Used a suggestive title to get clicks and then completely changed the tone of the video.',
    'Trolled your audience with a prank that went too far.',
    'Posted about a scandal that directly involved a friend or close connection.',
    'Created fake drama for a sponsored collaboration.',
    'Used a misleading caption to imply you were dating someone famous.',
    'Hooked up with someone for the “exposure.”',
    'Had a “fake relationship” to boost engagement.',
    'Teased explicit content or an NSFW post, but never delivered.',
    'Faked a breakup for sympathy and clicks.',
    'Stirred up rumors about another content creator.',
    'Sent a cheeky "oops, didn’t mean to post that" story or post.',
    'Partnered with another creator for a charity event.',
    'Deleted old controversial posts after they resurfaced.',
    'Shared a scandalous or embarrassing story to go viral.',
    'Used explicit language to appeal to a mature audience.',
    'Posted risky content to try and “cancel-proof” yourself.',
    'Talked trash about another creator\'s appearance or content style.',
    'Created clickbait about quitting content creation, only to return within days.',
    'Leaked a fake “exclusive” detail about an upcoming project.',
    'Created content that caused your family or friends to confront you.',
    'Filmed or posted something risky in a public or semi-public space.',
    'Got caught in a public scandal, but turned it into more content.'
  ];
  
  export default questions;
  